<template>
  <div>

    <v-navigation-drawer app v-model="drawer.left" left width="300">
      <div class="text-center">
        <h1>
          Oceans
        </h1>
        <p>
          Superstates connected to Countries connected to ports connected to
          oceans, seas and straits
        </p>
      </div>
      <br>
      <br>

      <v-slider v-model="textsize" thumb-label="always" min="0" max="250"
        label="Font size">
      </v-slider>


      <v-btn v-if="hideCountries" @click="hideCountries = false">
        Unhide countries
      </v-btn>
      <v-btn v-else @click="hideCountries = true">
        Hide countries
      </v-btn>
      <div>
        <v-btn v-if="onlySeas" @click="onlySeas = false">
          Not only seas
        </v-btn>
        <v-btn v-else @click="onlySeas = true">
          Only Seas
        </v-btn>
      </div>
      <v-slider v-model="strength" thumb-label="always" min="-2000" max="0"
        label="strength">
      </v-slider>
      <v-slider v-model="forceCollideFactor" thumb-label="always" min="0"
        max="1000" label="forceCollideFactor">
      </v-slider>

    </v-navigation-drawer>
    <v-navigation-drawer app v-model="drawer.open" right width="300">
      <div>
        {{
            focusedNode
        }}
      </div>
      <div>

        <pre>

          {{
              links.filter((l) => l.target.name === focusedNode).map(l => l.source.name)
          }}
            </pre>
      </div>
    </v-navigation-drawer>
    <div>

    </div>
    <div v-if="mapLoading">
      <v-progress-linear :size="50" color="primary" indeterminate>
      </v-progress-linear>
    </div>
    <div class="svgContainer" v-resize="onResize">
      <svg id="map" :viewBox="`0 0 ${width} ${height}`"
        preserveAspectRatio="xMinYMin meet" cursor="grab">
        <g id="nodesAndLinks">
          <g class="links">
            <g v-for="(link, linkI) in links" :key="linkI">
              <path :d="linkd(link)" :stroke="
                link.source.name === focusedNode ||
                  link.target.name === focusedNode
                  ? 'gold'
                  : 'grey'
              " :stroke-width="
  link.source.name === focusedNode ||
    link.target.name === focusedNode
    ? 4
    : 2
" opacity="0.5" fill="none" />
              <!-- <line
                :x1="link.source.x"
                :y1="link.source.y"
                :x2="link.target.x"
                :y2="link.target.y"
                :stroke="
                  link.source.name === focusedNode ||
                  link.target.name === focusedNode
                    ? 'gold'
                    : 'grey'
                "
                :stroke-width="
                  link.source.name === focusedNode ||
                  link.target.name === focusedNode
                    ? 6
                    : 3
                "
                opacity="0.5"
                cursor="pointer"
              ></line> -->
            </g>
          </g>
          <g class="nodes" v-if="nodes">
            <g v-for="(node, nodeI) in nodes" :key="nodeI">
              <circle @mouseover="focusedNode = node.name" :cx="node.x"
                :cy="node.y"
                :r="Math.sqrt(node.targets.length * forceCollideFactor)"
                :fill="circleFill(node)" cursor="pointer" opacity="0.8"
                stroke="black" stroke-width="1"></circle>
              <text :x="node.x" :y="node.y"
                :font-size="Math.sqrt(textsize * node.targets.length)"
                :fill="node.name === focusedNode ? 'blue' : 'black'"
                :stroke="node.name === focusedNode ? 'gold' : 'white'"
                stroke-width="2" stroke-opacity="0.3" cursor="pointer">
                {{ node.name }}
              </text>
            </g>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
// import _ from 'lodash';
import oceansJson from "@/data/oceans.json";

import * as d3 from "d3";
import { mapState } from "vuex";

export default {
  name: "Oceans",
  data() {
    return {

      windowSize: {
        x: 0,
        y: 0,
      },
      onlySeas: false,
      hideCountries: false,
      focusedNode: "",
      g: null,
      svg: null,
      ratio: 1.59,
      textsize: 100,
      strength: -165,
      forceCollideFactor: 29,

      factbook: null,
      simulation: null,
      mapLoading: true,
      oceansJson: oceansJson,
    };
  },
  watch: {
    onlySeas: function () {
      this.createSimulation();
      this.simulation.tick(2000);
    },
    hideCountries: function () {
      this.createSimulation();
      this.simulation.tick(2000);
    },
    links: function () {
      this.createSimulation();
      // this.simulation.tick(2000);
      // var i = 0;
      // while (i < 10) {
      //   this.createSimulation();
      //   this.simulation.tick(1000);
      //   i++;
      // }
      // this.mapLoading = false;
    },
    width: function () {
      this.createSimulation();
      this.simulation.tick(100);
    },
    ratio: function () {
      this.createSimulation();
    },
    strength: function () {
      this.createSimulation();
      this.simulation.tick(100);
    },
    forceCollideFactor: function () {
      this.createSimulation();
    },
  },
  mounted() {
    this.onResize()
    this.svg = d3.select("#map");
    this.g = d3.select("#nodesAndLinks");
    const zoom = d3
      .zoom()
      .scaleExtent([0.1, 10])
      .on("zoom", this.zoomed);
    this.svg.call(zoom);
    this.createSimulation();
    this.simulation.tick(100);
  },
  methods: {
    onResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      console.log(this.windowSize)
    },
    linkd(link) {
      var spacing = 5;
      return `M ${link.source.x} ${link.source.y} Q ${(link.target.x +
        link.source.x) /
        2 +
        (link.target.x > link.source.x ? +spacing : -spacing)} ${(link.target
          .y +
          link.source.y) /
        2 +
        (link.target.y > link.source.y ? -spacing : +spacing)} ${link.target.x
        } ${link.target.y}`;
    },
    tick() {
      this.createSimulation();
    },
    circleFill(node) {
      var circleFill = "grey";
      if (this.majorChokePoints.slice().includes(node.name)) {
        return "blue";
      }
      if (this.seas.slice().includes(node.name)) {
        if (node.name.includes("Ocean")) {
          return "cyan";
        } else if (node.name.includes("Sea")) {
          return "aqua";
        } else if (node.name.includes("Gulf")) {
          return "turquoise";
        } else {
          return "aquamarine";
        }
      }
      if (this.countries.slice().includes(node.name)) {
        return "red";
      }
      return circleFill;
    },
    zoomed(event) {
      const { transform } = event;
      this.g.attr("transform", transform);
      this.g.attr("stroke-width", 1 / transform.k);
    },
    createSimulation() {
      this.simulation = d3
        .forceSimulation(this.nodes)
        .force(
          "link",
          d3.forceLink(this.links).id((d) => d.name)
        )
        // .force(
        //   "collide",
        //   d3.forceCollide((d) =>
        //     Math.sqrt(d.targets.length * this.forceCollideFactor)
        //   )
        // )
        .force(
          "charge",
          d3.forceManyBody().strength((d) => d.targets.length * this.strength)
        )
        .force("x", d3.forceX())
        .force("y", d3.forceY())
        .force("center", d3.forceCenter(this.centerX, this.centerY));
      this.mapLoading = false;
    },
  },
  computed: {
    ...mapState(['drawer']),
    height() {
      return this.windowSize.y - 48;
    },
    width() {
      return this.windowSize.x - (this.drawer.left ? 300 : 0) - (this.drawer.open ? 300 : 0);
    },
    centerX() {
      return this.width / 2;
    },
    centerY() {
      return this.height / 2;
    },
    yellow() {
      return d3.interpolateYlGn(0);
    },
    majorChokePoints() {
      return [
        "Kerch Strait",
        "Bab el Mandeb",
        "Bass Strait",
        "Bosporus",
        "Cape of Good Hope",
        "Dardanelles",
        "Davis Strait",
        "Drake Passage",
        "Hudson Strait",
        "Luzon Strait",
        "Macassar Strait",
        "Mona Passage",
        "Mozambique Channel",
        "Northwestern Passages",
        "Panama Canal",
        "Strait of Denmark",
        "Strait of Dover",
        "Strait of Gibraltar",
        "Strait of Hormuz",
        "Strait of Malacca",
        "Suez Canal",
        "Sunda Strait",
        "Taiwan Strait",
        "The Sound",
        "Tsugaru Strait",
        "Windward Passage",
        "Yucatan Channel",
      ];
    },
    ports() {
      return [
        "Ho Chi Minh",
        "Bandar Abbas",
        "Cape Town",
        "Colombo",
        "Dubai Ports",
        "Durban",
        "Honolulu",
        "Jeddah",
        "Kelang",
        "Khor Fakkan",
        "Marsaxlokk",
        "Shahid Rajai",
        "Tanjung Priok",
        "Yokohama",
      ];
    },
    seas() {
      return [
        "Adriatic Sea",
        "Aegean Sea",
        "Alboran Sea",
        "Amundsen Sea",
        "Andaman Sea",
        "Arabian Sea",
        "Arafura Sea",
        "Arctic Ocean",
        "Baffin Bay",
        "Balearic Sea",
        "Baltic Sea",
        "Banda Sea",
        "Barents Sea",
        "Bay of Bengal",
        "Bay of Biscay",
        "Bay of Fundy",
        "Beaufort Sea",
        "Bellingshausen Sea",
        "Bering Sea",
        "Bismarck Sea",
        "Black Sea",
        "Caribbean Sea",
        "Celebes Sea",
        "Celtic Sea",
        "Ceram Sea",
        "Chukchi Sea",
        "Coral Sea",
        "East China Sea",
        "East Siberian Sea",
        "English Channel",
        "Flores Sea",
        "Great Australian Bight",
        "Greenland Sea",
        "Gulf of Aden",
        "Gulf of Alaska",
        "Gulf of Aqaba",
        "Gulf of Boni",
        "Gulf of Bothnia",
        "Gulf of California",
        "Gulf of Carpentaria",
        "Gulf of Finland",
        "Gulf of Guinea",
        "Gulf of Mexico",
        "Gulf of Oman",
        "Gulf of Panama",
        "Gulf of Riga",
        "Gulf of Saint Lawrence",
        "Gulf of Thailand",
        "Gulf of Tomini",
        "Gulf of Tonkin",
        "Halmahera Sea",
        "Hudson Bay",
        "Indian Ocean",
        "Inner Seas",
        "Ionian Sea",
        "Irish Sea",
        "Java Sea",
        "Kara Sea",
        "Labrador Sea",
        "Laccadive Sea",
        "Laptev Sea",
        "Ligurian Sea",
        "Mediterranean Sea",
        "Moluk Sea",
        "Mozambique Channel",
        "North Atlantic Ocean",
        "North Pacific Ocean",
        "North Sea",
        "Norwegian Sea",
        "Persian Gulf",
        "Philippine Sea",
        "Red Sea",
        "Rio de la Plata",
        "Ross Sea",
        "Savu Sea",
        "Scotia Sea",
        "Scotia Sea",
        "Sea of Azov",
        "Sea of Japan",
        "Sea of Marmara",
        "Sea of Okhotsk",
        "Solomon Sea",
        "South Atlantic Ocean",
        "South China Sea",
        "South Pacific Ocean",
        "Southern Ocean",
        "Sulu Sea",
        "Tasman Sea",
        "Timor Sea",
        "Tyrrhenian Sea",
        "Weddel Sea",
        "Weddel Sea",
        "White Sea",
        "Yellow Sea",
      ];
    },
    countries() {
      var countries = [
        "Poland",
        "Russia",
        "Egypt",
        "Thailand",
        "Vietnam",
        "European Union",
        "Germany",
        "Netherlands",
        "Belgium",
        "Australia",
        "New Zealand",
        "Chile",
        "Panama",
        "China",
        "South Korea",
        "India",
        "Italy",
        "Malaysia",
        "Argentina",
        "Brazil",
        "Canada",
        "France",
        "Indonesia",
        "Iran",
        "Jamaica",
        "Japan",
        "Malta",
        "Oman",
        "Saudi Arabia",
        "Singapore",
        "South Africa",
        "Spain",
        "Sri Lanka",
        "Taiwan",
        "Turkey",
        "United Arab Emirates",
        "United States",
        "United Kingdom",
        "Philippines",
      ];
      countries = countries.slice().sort();
      return countries;
    },
    nodes() {
      var nodes = oceansJson;
      nodes = nodes.slice().sort((a, b) => b.targets.length - a.targets.length);
      if (this.hideCountries && !this.onlySeas) {
        nodes = nodes
          .slice()
          .filter((n) => !this.countries.slice().includes(n.name));
      }
      if (this.onlySeas) {
        nodes = nodes
          .slice()
          .filter(
            (n) =>
              this.seas.slice().includes(n.name) ||
              this.majorChokePoints.slice().includes(n.name)
          );
      }
      return nodes;
    },
    links() {
      var links = [];
      this.nodes.forEach((node) => {
        node.targets.forEach((target) => {
          var targetNode = this.nodes.find((n) => n.name === target);
          if (targetNode) {
            links.push({
              source: node,
              target: this.nodes.find((n) => n.name === target),
            });
          } else {
            if (this.seas.includes(target)) {
              console.error("minssing sea node", target, "for", node.name);
            } else {
              // console.error("minssing node", target, "for", node.name);
            }
          }
        });
      });
      return links;
    },
  },
};
</script>


